import styled from "@emotion/styled";
import { Button, notification, Tooltip } from "antd";
import { useMemo, useState } from "react";
import DelegateModal from "screens/bridge/delegate-modal";
import DelegateConfirm from "screens/bridge/delegate-confirm";
// import TransactionSent from "components/transaction-sent";
import { useQueryStoremanConf, useQueryStoremanInfo } from "utils/queries";
import { useLocation } from "react-router";
import { useSendStoremanContractFunc } from "utils/useWeb3";
import { useWallet } from "context/wallet-context";
import BigNumber from "bignumber.js";
import { fromWei1 } from "utils";
import { useStoremanDelegatorInfo } from "./storeman-data";
import numeral from "numeral";
import Identicon from "identicon.js";
import {
  StoremanDelegatorInfoType,
  StoremanInfoType,
} from "types/storeman-type";
import { useQueryClient } from "react-query";
import StatusModal from "components/status-modal";
import ToolTipCus from "components/tooltips";
import { BridgeDataToolTip } from "config";
import { useLocalStorage } from "context/localstorage-context";
import BackImg from "assets/back.png";
import BackImg_Light from "assets/back_light.png";
import LinkIcon from "assets/link.png";
import success_icon from "assets/success_icon.png";
import error_icon from "assets/error_icon.png";

const Img = styled.img`
  top: -1px;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
`;

const Link = ({ txHash }: { txHash: string }) => (
  <div>
    View on Wanchain Explorer{" "}
    <a
      target="_blank"
      rel="noreferrer"
      href={`${process.env.REACT_APP_WANSCAN_API}/tx/${txHash}`}
    >
      <Img width={18} src={LinkIcon} alt="link" />
    </a>
  </div>
);

export default function StoremanDetail() {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [showTransactionSentModal, setShowTransactionSentModal] =
    useState(false);
  const [showWithdrawConfirmModal, setShowWithdrawConfirmModal] =
    useState(false);
  const [showClaimConfirmModal, setShowClaimConfirmModal] = useState(false);
  const storemanLists = useQueryStoremanInfo();
  const storemanDelegatorInfo = useStoremanDelegatorInfo();
  const storemanConf = useQueryStoremanConf();

  const client = useSendStoremanContractFunc();
  const { connected, connect } = useWallet();
  const queryClient = useQueryClient();
  const { theme } = useLocalStorage();

  const isdark = useMemo(() => theme === "dark", [theme]);

  const storemanInfo = useMemo(() => {
    if (storemanLists && storemanLists.length > 0) {
      const wkAddr = location.pathname.split("/storemandetail/")[1];
      const storeman = storemanLists.find(
        (v: StoremanInfoType) => v.wkAddr.toLowerCase() === wkAddr.toLowerCase()
      );
      let tmp: any;
      if (storeman) {
        tmp = Object.assign({}, storeman);
        tmp.myDeposit = "0";
        tmp.myIncentive = "0";
        tmp.quota = "0";
        storemanDelegatorInfo.forEach((j: StoremanDelegatorInfoType) => {
          if (storeman.wkAddr.toLowerCase() === j.wkAddr.toLowerCase()) {
            tmp.myQuited = j.quited;
            tmp.myDeposit = j.deposit.toString();
            tmp.myIncentive = j.incentive.toString();
            tmp.canDelegateOut = j.canDelegateOut;
            tmp.canDelegateClaim = j.canDelegateClaim;
          }
        });
        if (storemanConf) {
          const quota = new BigNumber(fromWei1(storeman.deposit))
            .plus(fromWei1(storeman.partnerDeposit || "0"))
            .multipliedBy(storemanConf.delegationMulti || "1")
            .minus(fromWei1(storeman.delegateDeposit || "0"))
            .toString(10);
          const quotaT = new BigNumber(quota).lt("0") ? "0" : quota;
          tmp.quota = numeral(quotaT)
            .format("0,0.00")
            .replace(/\.?0+$/, "");
        }
      } else {
        if (storemanDelegatorInfo.length === 0) return {};
        tmp = storemanDelegatorInfo.find(
          (i: StoremanDelegatorInfoType) =>
            i.wkAddr.toLowerCase() === wkAddr.toLowerCase()
        );
        tmp.name = tmp.wkAddr;
        tmp.storeman = "--";
        tmp.activity = "0";
        tmp.totalDeposit = "0";
        tmp.delegateFee = "0";
        tmp.powerWeight = "0";
        tmp.status = "Finished";
        tmp.imgdata = `data:image/png;base64,${new Identicon(
          tmp.wkAddr
        ).toString()}`;
        tmp.myDeposit = tmp.deposit.toString();
        tmp.myIncentive = tmp.incentive.toString();
      }
      return tmp || {};
    } else {
      return {};
    }
  }, [location.pathname, storemanConf, storemanDelegatorInfo, storemanLists]);

  const storeman = useMemo(() => {
    if (storemanLists && storemanLists.length > 0) {
      const tmp = storemanLists.find(
        (v: StoremanInfoType) =>
          v.wkAddr === location.pathname.split("/storemandetail/")[1]
      );
      return tmp;
    } else {
      return null;
    }
  }, [location.pathname, storemanLists]);

  const storemanDelegateIn = (amount: string) => {
    const amountInWei = new BigNumber(amount)
      .multipliedBy(Math.pow(10, 18))
      .toString(10);
    client("delegateIn", [storemanInfo.wkAddr], amountInWei)
      .on("receipt", (receipt: any) => {
        if (receipt && receipt.status) {
          notification.success({
            message: "Transaction completed",
            description: <Link txHash={receipt.transactionHash} />,
            className: `${
              isdark ? "noticeSuccess_dark" : "noticeSuccess_light"
            }`,
            icon: <img width={30} src={success_icon} alt="notification" />,
          });
          queryClient.invalidateQueries("useStoremanInfo");
          setShowTopUpModal(false);
        } else {
          notification.error({
            message: "Transaction Sent Failed",
            description: <Link txHash={receipt.transactionHash} />,
            className: `${
              isdark ? "noticeSuccess_dark" : "noticeSuccess_light"
            }`,
            icon: <img width={30} src={error_icon} alt="notification" />,
          });
        }
      })
      .on("error", (error: any) => {
        setShowTopUpModal(false);
      });
  };

  const storemanWithdraw = () => {
    client("delegateOut", [storemanInfo.wkAddr])
      .on("receipt", (receipt: any) => {
        if (receipt && receipt.status) {
          notification.success({
            message: "Transaction completed",
            description: <Link txHash={receipt.transactionHash} />,
            className: `${
              isdark ? "noticeSuccess_dark" : "noticeSuccess_light"
            }`,
            icon: <img width={30} src={success_icon} alt="notification" />,
          });
          queryClient.invalidateQueries("useStoremanInfo");
          setShowWithdrawConfirmModal(false);
        } else {
          notification.error({
            message: "Transaction Sent Failed",
            description: <Link txHash={receipt.transactionHash} />,
            className: `${
              isdark ? "noticeSuccess_dark" : "noticeSuccess_light"
            }`,
            icon: <img width={30} src={error_icon} alt="notification" />,
          });
        }
      })
      .on("error", (error: any) => {
        setShowWithdrawConfirmModal(false);
      });
  };

  const storemanClaim = () => {
    client(
      `${
        storemanInfo.canDelegateClaim
          ? "delegateClaim"
          : "delegateIncentiveClaim"
      }`,
      [storemanInfo.wkAddr]
    )
      .on("receipt", (receipt: any) => {
        notification.success({
          message: "Transaction completed",
          description: <Link txHash={receipt.transactionHash} />,
          className: `${isdark ? "noticeSuccess_dark" : "noticeSuccess_light"}`,
          icon: <img width={30} src={success_icon} alt="notification" />,
        });
        queryClient.invalidateQueries("useStoremanInfo");
        setShowClaimConfirmModal(false);
      })
      .on("error", (error: any) => {
        setShowClaimConfirmModal(false);
      });
  };

  const handleTopUp = async () => {
    if (connected) {
      setShowTopUpModal(true);
    } else {
      await connect();
      setShowTopUpModal(true);
    }
  };

  const handleWithdraw = async () => {
    if (connected) {
      setShowWithdrawConfirmModal(true);
    } else {
      await connect();
      setShowWithdrawConfirmModal(true);
    }
  };

  const handleClaim = async () => {
    if (connected) {
      setShowClaimConfirmModal(true);
    } else {
      await connect();
      setShowClaimConfirmModal(true);
    }
  };

  const goBack = () => {
    window.history.back();
  };

  const unclaimable = useMemo(() => {
    if (storeman && storemanInfo) {
      const tmp = storemanInfo.canDelegateClaim
        ? new BigNumber(storemanInfo.myIncentive)
            .plus(storemanInfo.myDeposit)
            .toString(10)
        : storemanInfo.myIncentive;
      return numeral(tmp)
        .format("0,0.0000")
        .replace(/\.?0+$/, "");
    } else if (
      !storeman &&
      storemanInfo &&
      storemanInfo.status === "Finished"
    ) {
      const tmp = new BigNumber(storemanInfo.myIncentive)
        .plus(storemanInfo.myDeposit)
        .toString(10);
      return numeral(tmp)
        .format("0,0.0000")
        .replace(/\.?0+$/, "");
    } else {
      return "0";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeman, storemanInfo, storemanInfo.canDelegateClaim]);

  const enableTopupButton = useMemo(() => {
    if (storeman && storemanInfo && !storemanInfo.myQuited) return false;
    return true;
  }, [storeman, storemanInfo]);

  const disableTopupTitle = useMemo(() => {
    console.log(storeman, storemanInfo, storemanInfo.myQuited);
    if (storeman && storemanInfo && storemanInfo.myQuited) {
      return "The delegated WAN assets are in the exit process and cannot be topped up.";
    } else {
      return "";
    }
  }, [storeman, storemanInfo]);

  const enableExitButton = useMemo(() => {
    return (
      !storeman ||
      !storemanInfo ||
      !(storemanInfo.canDelegateOut && !storemanInfo.myQuited) ||
      storemanInfo.canDelegateClaim
    );
  }, [storeman, storemanInfo]);

  const disableExitTitle = useMemo(() => {
    if (storeman && storemanInfo && storemanInfo.myQuited) {
      return "You have already exited, no need to exit again.";
    } else if (
      storeman &&
      storemanInfo &&
      !storemanInfo.myQuited &&
      !storemanInfo.canDelegateOut
    ) {
      return "Exiting is unavailable from the 1st to the 9th during the Storeman group's cycle switch. Please try again after the 9th.";
    } else {
      return "";
    }
  }, [storeman, storemanInfo]);

  return (
    <MainBody>
      <BackBtn onClick={goBack}>
        <div
          className="bg-white dark:bg-common-1"
          style={{ width: "32px", borderRadius: "5px" }}
        >
          <img src={isdark ? BackImg : BackImg_Light} alt="back" width={32} />
        </div>
      </BackBtn>
      <DetailAddr className="bg-white dark:bg-dark-222">
        <DetailAddr1>
          <Icon src={storemanInfo.imgdata} alt="" />
        </DetailAddr1>
        <DetailAddr2>
          <p
            className="text-light-0 dark:text-white"
            style={{
              fontSize: "24px",
              wordBreak: "break-word",
              textAlign: "left",
            }}
          >
            {storemanInfo.name}
          </p>
          <p
            className="text-light-333 dark:text-dark-0"
            style={{
              color: "#757575",
              wordBreak: "break-word",
              textAlign: "left",
            }}
          >
            {storemanInfo.wkAddr}
          </p>
        </DetailAddr2>
      </DetailAddr>
      <DetailInfo height={"26.5rem"} className="bg-white dark:bg-dark-222">
        {/********* / Part1  *********/}
        <InfoRow>
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Total Staked Amount
              <ToolTipCus title={BridgeDataToolTip[0]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {storemanInfo.totalDeposit || "--"}
              </span>
              <span>WAN</span>
            </p>
          </Data>
          <LineCloumn isdark={isdark} />
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Remaining Quota
              <ToolTipCus title={BridgeDataToolTip[1]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {storemanInfo.quota || "--"}
              </span>
              <span>WAN</span>
            </p>
          </Data>
        </InfoRow>
        <LineRow isdark={isdark} />

        {/********* / Part2  *********/}
        <InfoRow>
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Activity
              <ToolTipCus title={BridgeDataToolTip[2]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {storemanInfo.activity || "--"}
              </span>
            </p>
          </Data>
          <LineCloumn isdark={isdark} />
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Delegation Fee(%)
              <ToolTipCus title={BridgeDataToolTip[3]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {isNaN(storemanInfo.delegateFee)
                  ? "--"
                  : storemanInfo.delegateFee / 100 + "%"}
              </span>
            </p>
          </Data>
        </InfoRow>
        <LineRow isdark={isdark} />

        {/********* / Part3  *********/}
        <InfoRow>
          <Data>
            <DataTitle className="text-light-444 dark:text-dark-0">
              Status
              <ToolTipCus title={BridgeDataToolTip[4]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {storemanInfo.status || "--"}
              </span>
            </p>
          </Data>
          <LineCloumn isdark={isdark} />
          <Data>
            <DataTitle>
              Auto Renew
              <ToolTipCus title={BridgeDataToolTip[5]} />
            </DataTitle>
            <p>
              <span className="text-light-0 dark:text-white">
                {storemanInfo && storemanInfo.autoRenew !== undefined
                  ? storemanInfo.autoRenew.toString()
                  : "--"}
              </span>
            </p>
          </Data>
        </InfoRow>
      </DetailInfo>
      {new BigNumber(storemanInfo.myDeposit).eq("0") ? (
        <Btn>
          <Button block onClick={handleTopUp}>
            Delegate
          </Button>
        </Btn>
      ) : (
        <MyDelegate className="bg-white dark:bg-dark-222">
          <InfoRow count={2} direction="column">
            <Data1>
              <DataTitle className="text-light-444 dark:text-dark-0">
                My Rewards
              </DataTitle>
              <div>
                <span className="text-light-111 dark:text-white">
                  {numeral(storemanInfo.myIncentive)
                    .format("0,0.0000")
                    .replace(/\.?0+$/, "")}
                </span>
                <span>WAN</span>
                <Line />
                <span style={{ fontWeight: 400 }}>Claimable: </span>
                <span style={{ fontWeight: 400, color: "#228CB7" }}>
                  {unclaimable}
                </span>
                <span style={{ marginLeft: "5px" }}>WAN</span>
              </div>
            </Data1>
          </InfoRow>
          <LineRow isdark={isdark} />
          <InfoRow count={2} direction="column">
            <Data1>
              <DataTitle className="text-light-444 dark:text-dark-0">
                My Delegations
              </DataTitle>
              <MyActions
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>
                  <span className="text-light-111 dark:text-white">
                    {numeral(storemanInfo.myDeposit)
                      .format("0,0.0000")
                      .replace(/\.?0+$/, "")}
                  </span>
                  <span className="text-light-444 dark:text-dark-0">WAN</span>
                </span>
                <BtnArea isdark={isdark}>
                  <Tooltip title={enableTopupButton ? disableTopupTitle : ""}>
                    <Button disabled={enableTopupButton} onClick={handleTopUp}>
                      Top-up
                    </Button>
                  </Tooltip>
                  <Tooltip title={enableExitButton ? disableExitTitle : ""}>
                    <Button
                      disabled={enableExitButton}
                      onClick={handleWithdraw}
                    >
                      Exit
                    </Button>
                  </Tooltip>
                  <Button onClick={handleClaim}>Claim</Button>
                </BtnArea>
              </MyActions>
            </Data1>
          </InfoRow>
        </MyDelegate>
      )}
      {showModal && (
        <DelegateModal
          type="Delegate"
          visible={showModal}
          onCancel={() => setShowModal(false)}
          storemanInfo={storemanInfo}
          action={() => {}}
        />
      )}
      {showTopUpModal && (
        <DelegateModal
          type={
            new BigNumber(storemanInfo.myDeposit).eq("0")
              ? "Delegate"
              : "Top-up"
          }
          visible={showTopUpModal}
          onCancel={() => setShowTopUpModal(false)}
          storemanInfo={storemanInfo}
          action={(v: string) => storemanDelegateIn(v)}
        />
      )}
      {showWithdrawConfirmModal && (
        <DelegateConfirm
          visible={showWithdrawConfirmModal}
          onCancel={() => setShowWithdrawConfirmModal(false)}
          storemanInfo={storemanInfo}
          action={storemanWithdraw}
          amount={storemanInfo.myDeposit}
          type="Undelegate WAN"
        />
      )}
      {showClaimConfirmModal && (
        <DelegateConfirm
          visible={showClaimConfirmModal}
          onCancel={() => setShowClaimConfirmModal(false)}
          storemanInfo={storemanInfo}
          action={storemanClaim}
          amount={unclaimable}
          type="Claim"
        />
      )}
      {showTransactionSentModal && (
        <StatusModal
          type="success"
          visible={true}
          onCancel={() => setShowTransactionSentModal(false)}
        />
      )}
    </MainBody>
  );
}

const MainBody = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  text-align: center;
  align-items: center;
  gap: 2rem;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const BackBtn = styled.div`
  width: 80rem;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 16px;
  }
`;

const DetailAddr = styled.div`
  width: 80rem;
  border-radius: 16px;
  backdrop-filter: blur(50px);
  display: flex;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }
`;

const DetailInfo = styled.div<{
  height: string;
}>`
  width: 80rem;
  height: ${(props) => props.height};
  border-radius: 16px;
  backdrop-filter: blur(50px);
  padding: 5px 20px;

  @media (max-width: 768px) {
    width: 100%;
    height: 70rem;
  }
`;

const Btn = styled.div`
  width: 80rem;
  .ant-btn {
    border-radius: 16px;
    height: 60px;
    color: white;
    border: none;
    background: #0f68aa;
  }
  .ant-btn:hover {
    background: #0f68aa;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 2rem;
  }
`;

const MyDelegate = styled.div`
  width: 80rem;
  border-radius: 16px;
  opacity: 1;
  padding: 10px 20px;
  backdrop-filter: blur(30px);
  gap: 1rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DetailAddr1 = styled.div`
  width: 90px;
  line-height: 90px;
`;

const DetailAddr2 = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 90px;
  justify-content: center;
  align-items: flex-start;
  p {
    margin-bottom: 0;
  }
`;

const InfoRow = styled.div<{
  count?: number;
  direction?: string;
}>`
  width: 100%;
  display: flex;
  min-height: 8.5rem;
  height: ${(props) => `calc(100% / ${props.count || 4})`};
  flex-direction: ${(props) => props.direction || "row"};

  @media (max-width: 768px) {
    flex-direction: column;
    height: initial;
    justify-content: space-around;
    margin: 2rem 0;
  }
`;

const LineRow = styled.div<{
  isdark: boolean;
}>`
  border-bottom: ${(props) =>
    props.isdark ? "1px solid #1E3546" : "1px solid #EBEFF2"};
  width: 100%;
`;

const Data = styled.div`
  display: inline-block;
  width: 50%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin-bottom: 0;
    font-weight: bold;

    span:first-of-type {
      font-size: 32px;
      display: inline-block;
      margin-right: 10px;
    }
    span:not(:first-of-type) {
      font-size: 12px;
      color: #757575;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LineCloumn = styled.div<{
  isdark: boolean;
}>`
  height: 100%;
  border-left: ${(props) =>
    props.isdark ? "1px solid #1E3546" : "1px solid #EBEFF2"};
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100;
    border-bottom: ${(props) =>
      props.isdark ? "1px solid #1E3546" : "1px solid #EBEFF2"};
    border-left: 0;
  }
`;

const DataTitle = styled.span<{
  color?: string;
}>`
  font-size: 16px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${(props) => props.color || "#757575"};
  position: relative;
`;

const BtnArea = styled.div<{
  isdark: boolean;
}>`
  display: inline-flex;
  justify-content: space-between;
  gap: 20px;

  span {
    font-size: 16px !important;
    display: inline-block;
    margin-right: 0 !important;
  }
  .ant-btn:first-of-type {
    background: ${(props) => (props.isdark ? "#051f31" : "#0F68AA")};
    border-radius: 16px;
    border: 1px solid #2fbdf4;
    color: #fff;
  }
  .ant-btn:first-of-type:hover {
    background: ${(props) => (props.isdark ? "#051f31" : "#0F68AA")};
  }
  .ant-btn {
    width: 100px;
    border-radius: 16px;
    height: 30px;
    color: white;
    border: none;
    background: #0f68aa;
  }
  .ant-btn:hover {
    color: white;
    background: #0f68aa;
  }

  @media (max-width: 768px) {
    justify-content: space-around;
    margin-top: 2rem;
  }
`;

const Data1 = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    margin-bottom: 0;
    font-weight: bold;

    span:first-of-type {
      font-size: 32px;
      display: inline-block;
      margin-right: 10px;
    }
    span:not(:first-of-type) {
      font-size: 12px;
      color: #757575;
    }
  }
`;

const Icon = styled.img`
  width: 60px;
  border-radius: 8px;
`;

const MyActions = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Line = styled.span`
  border-left: 1px solid #828f98;
  margin: 0 1rem;
`;
