import styled from "@emotion/styled";
import { Input, Switch, Radio, Select } from "antd";
import { ChangeEvent, useMemo, useState } from "react";
import CardPart from "components/storeman-card-part";
import BigNumber from "bignumber.js";
import Numeral from "numeral";
import DataLoading from "assets/data-loading.png";
import { useLocalStorage } from "context/localstorage-context";

const { Search } = Input;

const TYPE: Record<string, string> = {
  "0": "TVL",
  "1": "Fee Rate",
  "2": "Activity",
  "3": "Quota",
};

export default function StoremanLists({
  storemanList,
  withdrawStoreman,
}: {
  storemanList: any;
  withdrawStoreman: any;
}) {
  const [sortedBy, setSortedBy] = useState("0");
  const [searchName, setSearchName] = useState("");
  const { theme, bridgeSwitchOn, setBridgeSwitchOn } = useLocalStorage();
  const isDark = useMemo(() => theme === "dark", [theme]);

  const onChange = (data: ChangeEvent<HTMLInputElement>) =>
    setSearchName(data.target.value);

  const sortFunc = (stakerList: any, type: string) => {
    switch (type) {
      case "0":
        return stakerList.sort(
          (
            a: { totalDeposit: string; name: string },
            b: { totalDeposit: string; name: string }
          ) => {
            if (
              (Numeral(b.totalDeposit).value() || 0) ===
              (Numeral(a.totalDeposit).value() || 0)
            ) {
              return a.name.localeCompare(b.name);
            } else {
              return (
                (Numeral(b.totalDeposit).value() || 0) -
                (Numeral(a.totalDeposit).value() || 0)
              );
            }
          }
        );
      case "1":
        return stakerList.sort(
          (
            a: { powerWeight: string; name: string },
            b: { powerWeight: string; name: string }
          ) => {
            if (
              (Numeral(b.powerWeight).value() || 0) ===
              (Numeral(a.powerWeight).value() || 0)
            ) {
              return a.name.localeCompare(b.name);
            } else {
              return (
                (Numeral(a.powerWeight).value() || 0) -
                (Numeral(b.powerWeight).value() || 0)
              );
            }
          }
        );
      case "2":
        return stakerList.sort(
          (
            a: { activity: string; name: string },
            b: { activity: string; name: string }
          ) => {
            if (
              (Numeral(b.activity).value() || 0) ===
              (Numeral(a.activity).value() || 0)
            ) {
              return a.name.localeCompare(b.name);
            } else {
              return (
                (Numeral(b.activity).value() || 0) -
                (Numeral(a.activity).value() || 0)
              );
            }
          }
        );
      case "3":
        return stakerList.sort(
          (
            a: { quota: string; name: string },
            b: { quota: string; name: string }
          ) => {
            if (
              (Numeral(b.quota).value() || 0) ===
              (Numeral(a.quota).value() || 0)
            ) {
              return a.name.localeCompare(b.name);
            } else {
              return (
                (Numeral(b.quota).value() || 0) -
                (Numeral(a.quota).value() || 0)
              );
            }
          }
        );
    }
  };

  const setActivityZeroEnd = (stakerList: any) => {
    const activityZeroArr = stakerList.filter(
      (v: any) => (Numeral(v.activity).value() || "0") === "0"
    );
    const activityNotZeroArr = stakerList.filter(
      (v: any) => (Numeral(v.activity).value() || "0") !== "0"
    );
    return activityNotZeroArr.concat(activityZeroArr);
  };

  const storemanListByFilter = useMemo(() => {
    let tmp: any;
    if (bridgeSwitchOn) {
      tmp = [...storemanList, ...withdrawStoreman].filter((v: any) =>
        new BigNumber(v.myDeposit).gt("0")
      );
    } else {
      tmp = storemanList;
    }
    let tmp1 = setActivityZeroEnd(sortFunc(tmp, sortedBy));
    return tmp1.filter(
      (v: any) =>
        searchName === "" ||
        v.name.toLowerCase().includes(searchName.toLowerCase())
    );
  }, [bridgeSwitchOn, sortedBy, storemanList, withdrawStoreman, searchName]);

  const isLoading = useMemo(
    () => !(storemanList && storemanList.length > 0),
    [storemanList]
  );

  const handleSwitch = (checked: boolean) => {
    setBridgeSwitchOn(checked);
    window.localStorage.bridgeSwitchOn = checked;
  };

  return (
    <Main>
      <Header isDark={isDark}>
        <Title className="text-light-0 dark:text-dark-111">Bridge Nodes</Title>
        <Search
          placeholder="Search Names"
          onChange={onChange}
          style={{ width: 200 }}
        />
      </Header>
      <FilterArea isDark={isDark}>
        <Part isDark={isDark}>
          <MyDele1 className="text-light-0 dark:text-dark-0">
            My Delegations
          </MyDele1>
          <Switch
            checked={bridgeSwitchOn}
            onChange={handleSwitch}
            size="small"
            className="bg-light-777 dark:bg-dark-444"
          />
        </Part>
        <Part isDark={isDark} className="part">
          <MyDele2 className="text-light-0 dark:text-dark-0">
            Sorted by:
          </MyDele2>
          <Radio.Group
            value={sortedBy}
            onChange={(e) => setSortedBy(e.target.value)}
          >
            {Object.keys(TYPE).map((v: string) => (
              <Radio.Button key={v} value={v} onClick={() => setSortedBy(v)}>
                {TYPE[v]}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Part>
        <PartMedia isDark={isDark}>
          <Select
            showArrow={false}
            defaultValue={TYPE[0]}
            style={{ width: 120 }}
            onSelect={(v) => setSortedBy(v)}
            options={Object.keys(TYPE).map((v) => ({
              value: v,
              label: TYPE[v],
            }))}
          />
        </PartMedia>
      </FilterArea>
      <MainArea $loading={isLoading}>
        {isLoading ? (
          <img src={DataLoading} alt="loading" width={150} />
        ) : (
          storemanListByFilter.map((storeman: any, index: number) => (
            <CardPart storeman={storeman} key={index} />
          ))
        )}
      </MainArea>
    </Main>
  );
}

const Main = styled.div`
  width: 100%;
  margin-top: 2rem;
  height: calc(100% - 140px - 2rem);
  display: flex;
  flex-direction: column;
`;

const Header = styled.div<{
  isDark: boolean;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;

  .ant-input {
    color: ${(props) => (props.isDark ? "#fff" : "#000")};
    font-size: 16px;
    background-color: ${(props) =>
      props.isDark ? "rgba(0, 0, 0, 0.1)" : "#EBEFF2"};
    border-radius: 20px !important;
    border: ${(props) =>
      props.isDark
        ? "1px solid rgba(255, 255, 255, 0.15)"
        : "1px solid rgba(0, 0, 0, 0.1)"};
  }
  .ant-input:hover {
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
  .ant-input:focus {
    box-shadow: none;
  }
  .ant-input-group {
    border-radius: 20px !important;
  }
  .ant-input-group-addon {
    display: none;
  }
`;

const Title = styled.span`
  font-size: 24px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
`;

const MyDele1 = styled.span`
  font-size: 18px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  line-height: 21px;
  display: inline-block;
  margin-right: 1rem;
`;

const MyDele2 = styled.span`
  font-size: 18px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  line-height: 21px;
  display: inline-block;
  margin-right: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const FilterArea = styled.div<{
  isDark: boolean;
}>`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;

  .ant-switch-checked {
    background: ${(props) =>
      props.isDark ? "#2FBDF4!important" : "#0F68AA!important"};
    border: none !important;
    border: 1px solid rgba(255, 255, 255, 0);
  }
  .ant-switch {
    margin-top: 2px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.1);
  }
  .ant-switch .ant-switch-handle {
    top: 1px;
  }

  .ant-switch-checked .ant-switch-handle {
    top: 2px;
  }

  .ant-switch-small {
    min-width: 36px;
  }

  @media (max-width: 768px) {
    margin-top: 20px;

    .part {
      display: none;
    }
  }
`;

const Part = styled.div<{
  isDark: boolean;
}>`
  display: inline-block;

  .ant-radio-button-wrapper {
    min-width: 93px;
    margin: 0 3px;
    background: ${(props) => (props.isDark ? "rgba(0, 0, 0, 0.1)" : "#EBEFF2")};
    border-radius: 20px;
    border: ${(props) =>
      props.isDark
        ? "1px solid rgba(255, 255, 255, 0.15)"
        : "1px solid rgba(0,0,0,0.1)"};
    color: ${(props) => (props.isDark ? "#818D96" : "#8F9193")};
    font-size: 14px;
  }
  .ant-radio-button-wrapper::before {
    display: none;
  }
  .ant-radio-button-wrapper-checked,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-of-type,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border-color: ${(props) => (props.isDark ? "#2FBDF4" : "#0F68AA")};
    color: ${(props) => (props.isDark ? "#2FBDF4" : "#0F68AA")};
  }
`;

const PartMedia = styled.div<{
  isDark: boolean;
}>`
  display: none;

  @media (max-width: 768px) {
    display: block;

    .ant-select-selector {
      border: ${(props) =>
        props.isDark
          ? "1px solid #14DACA!important"
          : "1px solid rgba(0,0,0,0.1)!important"};
      color: ${(props) =>
        props.isDark ? "#14DACA!important" : "#8F9193!important"};
      border-radius: 20px !important;
      background: ${(props) =>
        props.isDark ? "#2B0F14!important" : "#EBEFF2!important"};
    }
  }
`;

const MainArea = styled.div<{
  $loading: boolean;
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$loading ? "none" : "repeat(4, 1fr)"};
  grid-column-gap: 1rem;
  width: 100%;
  margin-top: 2rem;
  justify-content: space-between;
  place-content: center;
  flex-grow: 1;

  @media (max-width: 768px) {
    grid-template-columns: ${(props) => (props.$loading ? "none" : "100%")};
  }
`;
