import styled from "@emotion/styled";
import { Modal, Input, Button, Form } from "antd";
import { useWallet } from "context/wallet-context";
import { useMemo, useState } from "react";
import { StoremanGroupInfo } from "types/pos-staker";
import DelegateConfirm from "./delegate-confirm";
import numeral from "numeral";
import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import BigNumber from "bignumber.js";
import { useQueryBalance } from "utils/queries";
import { useStoremanGroupInfo } from "./storeman-data";
import { useLocalStorage } from "context/localstorage-context";
import { checkAmountUnit } from "utils";

export default function DelegateModal({
  type,
  visible,
  onCancel,
  storemanInfo,
  action,
}: {
  type: string;
  visible: boolean;
  onCancel: () => void;
  storemanInfo: StoremanGroupInfo;
  action: (v: string) => void;
}) {
  const [showConfirm, setShowConfirm] = useState(false);
  const { address } = useWallet();
  const { data: balance } = useQueryBalance("WAN", true);
  const StoremanGroupInfo = useStoremanGroupInfo(storemanInfo.groupId);
  const { theme } = useLocalStorage();
  const isdark = useMemo(() => theme === "dark", [theme]);

  const minDelegateIn = useMemo(
    () =>
      StoremanGroupInfo
        ? new BigNumber(StoremanGroupInfo.minDelegateIn)
            .dividedBy(Math.pow(10, 18))
            .toString()
        : "0",
    [StoremanGroupInfo]
  );

  const [form] = Form.useForm();

  const checkAmount = (rule: RuleObject, value: StoreValue) => {
    try {
      if (!checkAmountUnit(value) || new BigNumber(value).lte(0)) {
        return Promise.reject("Please enter correct amount!");
      }
      if (new BigNumber(value).gte(balance || "0")) {
        return Promise.reject("Please enter correct amount!");
      }
      if (new BigNumber(value).lt(minDelegateIn)) {
        return Promise.reject(`Minimum amount should be ${minDelegateIn} WAN`);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject("Please enter correct amount!");
    }
  };

  const getRewardTime = () => {
    const time = new Date(Date.now() + 24 * 3600 * 1000);
    const utc = Date.UTC(
      time.getUTCFullYear(),
      time.getUTCMonth(),
      time.getUTCDate()
    );
    return new Date(utc).toUTCString();
  };

  const onOk = () => {
    form
      .validateFields()
      .then(() => {
        setShowConfirm(true);
      })
      .catch((error) => {
        console.log(error, "onOk");
      });
  };

  return (
    <Container>
      <Modal
        className={isdark ? "darkModal" : "lightModal"}
        maskClosable={true}
        closable={false}
        destroyOnClose={true}
        style={{ marginTop: "8%", borderRadius: "16px", background: "#051F31" }}
        width={700}
        mask={true}
        open={visible}
        title={
          <Title className="text-light-0 dark:text-white">
            {type} WAN with {storemanInfo.name}
          </Title>
        }
        footer={null}
        onCancel={onCancel}
      >
        <Form form={form} name="delegate">
          <Form.Item
            name="amount"
            rules={[{ required: true, validator: checkAmount }]}
          >
            <Input />
          </Form.Item>
        </Form>
        <AddrInfo>
          <span>Balance</span>
          <span>
            <Data>
              {numeral(balance)
                .format("0,0.0000")
                .replace(/\.?0+$/, "")}
            </Data>{" "}
            WAN
          </span>
        </AddrInfo>
        <AddrInfo>
          <span>Delegating from</span>
          <AddrText>{address}</AddrText>
        </AddrInfo>
        <BtnArea isdark={isdark}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onOk}>OK</Button>
        </BtnArea>
        <Info isdark={isdark}>
          <div>Notice</div>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              Delegated WAN will begin to accumulate rewards at{" "}
              {getRewardTime()}. Please remember to manually claim your daily
              rewards.
            </li>
            <li>
              Once you undelegate your WAN, the funds will be returned to your
              original wallet address within a period of{" "}
              <span style={{ color: "#fe4949" }}>10 to 30 days</span>.
            </li>
            <li>
              If you perform an undelegate operation within the current month,
              you will be able to manually claim the funds to your account on
              the{" "}
              <span style={{ color: "#fe4949" }}>
                10th of the following month
              </span>
              .
            </li>
          </ul>
        </Info>
      </Modal>
      {showConfirm && (
        <DelegateConfirm
          visible={showConfirm}
          onCancel={() => setShowConfirm(false)}
          storemanInfo={storemanInfo}
          action={action}
          amount={form.getFieldValue("amount")}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  .ant-input {
    border: 1px solid #2fbdf4;
  }
`;

const Title = styled.span`
  font-size: 24px;
  font-family: Inter-Bold, Inter;
  font-weight: 400;
  line-height: 30px;
`;

const AddrInfo = styled.div`
  display: flex;
  color: #757575;
  font-size: 14px;
  justify-content: space-between;

  span {
    color: #818d96;
  }
`;

const Data = styled.span`
  color: #2fbdf4 !important;
`;

const BtnArea = styled.div<{
  isdark: boolean;
}>`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 20px;

  .ant-btn:first-of-type {
    background: ${(props) => (props.isdark ? "#1E3546" : "#E5E5E5")};
    border-radius: 16px;
    color: ${(props) => (props.isdark ? "#818D96" : "##999999")};
  }
  .ant-btn:first-of-type:hover {
    background: ${(props) => (props.isdark ? "#1E3546" : "#E5E5E5")};
  }
  .ant-btn {
    width: calc((100% - 20px) / 2);
    border-radius: 16px;
    height: 60px;
    color: white;
    border: none;
    background: #0f68aa;
  }
  .ant-btn:hover {
    background: #0f68aa;
  }
`;

const Info = styled.div<{
  isdark: boolean;
}>`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${(props) => (props.isdark ? "#818d96" : "#999")};
  margin-top: 20px;
  text-align: left;
  border: ${(props) =>
    props.isdark ? "1px solid rgba(255, 255, 255, 0.1)" : "1px solid #F5F5F5"};
  border-radius: 16px;
  padding: 15px;
  background-color: ${(props) => (props.isdark ? "#051F31" : "#F5F5F5")};

  ul {
    padding-left: 1rem;
  }
`;

const AddrText = styled.span`
  @media (max-width: 768px) {
    width: 60%;
    text-align: right;
  }
`;
