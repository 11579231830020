import { useMount } from "utils";
import React, { ReactNode, useState } from "react";
import Web3Modal from "@wandevs/web3modal";
import { WanWalletConnector } from "@web3-react-wan/wanwallet-connector";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { provider } from "web3-core";
import { OneID } from "@oneid-xyz/inspect";

const network = process.env.REACT_APP_NETWORK;

function initWeb3(provider: provider) {
  const web3 = new Web3(provider);
  return web3;
}

const getProviderOptions = () => ({
  wanmask: {
    package: {},
    opts: {
      config: {},
    },
  },
  metax: {
    package: {},
    opts: {
      config: {},
    },
  },
  wanwallet: {
    package: new WanWalletConnector({
      chainId: network === "MAINNET" ? 888 : 999,
      url:
        network === "MAINNET"
          ? "https://gwan-ssl.wandevs.org:56891"
          : "https://gwan-ssl.wandevs.org:46891",
      pollingInterval: 15000,
      requestTimeoutMs: 300000,
    }),
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "326fb0397704475abffcfa9ca9c0ee5a",
      rpcUrl: "https://gwan-ssl.wandevs.org:56891",
      chainId: network === "MAINNET" ? 888 : 999,
      networkId: network === "MAINNET" ? 888 : 999,
      rpc: {
        888: "https://gwan-ssl.wandevs.org:56891",
        999: "https://gwan-ssl.wandevs.org:46891",
      },
    },
  },
});

const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  disableInjectedProvider: false,
  providerOptions: getProviderOptions(),
});

const WalletContext = React.createContext<
  | {
      address: string;
      balance: string;
      web3: any;
      provider: provider;
      connected: boolean;
      networkId: number;
      chainType: string;
      resetApp: () => void;
      connect: () => void;
      getLogo: () => any;
      oneid: string;
    }
  | undefined
>(undefined);
WalletContext.displayName = "WalletContext";

interface INITIAL_STATE_TYPE {
  address: string;
  web3: any;
  provider: provider;
  connected: boolean;
  networkId: number;
  chainType: string;
  balance: string;
  oneid: string;
}

const INITIAL_STATE = {
  address: "",
  web3: null,
  provider: null,
  connected: false,
  networkId: network === "MAINNET" ? 888 : 999,
  chainType: "wan",
  balance: "0",
  oneid: "",
};

export const WalletProvider = ({ children }: { children: ReactNode }) => {
  // const queryClient = useQueryClient();
  // const { data: address, run, setData: setAddress } = useAsync<Address[] | null>();
  const [walletModal, setWalletModal] =
    useState<INITIAL_STATE_TYPE>(INITIAL_STATE);

  const connect = async () => {
    try {
      let provider;
      if (window.injectWeb3) {
        provider = await web3Modal.connectTo("wanwallet");
      } else {
        provider = await web3Modal.connect();
      }
      await subscribeProvider(provider);
      if (!window.injectWeb3) {
        await provider.enable();
      }

      const web3 = initWeb3(provider);

      const accounts = await web3.eth.getAccounts();

      const address = accounts[0];

      const balance = web3.utils.fromWei(await web3.eth.getBalance(address));

      const networkId = await web3.eth.net.getId();

      const oneidInstance = new OneID();
      await oneidInstance.systemConfig.initConfig();
      const oneid = await oneidInstance.getPrimaryName(address);

      await setWalletModal({
        web3,
        oneid,
        provider,
        connected: true,
        address,
        networkId,
        balance,
        chainType: ["wanmask", "wanwallet"].includes(web3Modal.cachedProvider)
          ? "wan"
          : "eth",
      });
    } catch (error) {
      console.error(error);
      web3Modal.clearCachedProvider();
    }
  };

  const resetApp = async () => {
    const { web3 } = walletModal;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    web3.currentProvider.removeAllListeners();
    await web3Modal.clearCachedProvider();
    setWalletModal({
      ...INITIAL_STATE,
    });
  };

  const subscribeProvider = async (provider: any) => {
    if (!provider || typeof provider === "string" || !provider.on) return;

    provider.on("disconnect", () => resetApp());
    provider.on("accountsChanged", async (accounts: string) => {
      await setWalletModal({ ...walletModal, address: accounts[0] });
    });
    provider.on("chainChanged", async (event: any) => {
      const { web3 } = walletModal;
      if (web3) {
        const networkId = await web3.eth.net.getId();
        await setWalletModal({ ...walletModal, networkId });
      } else {
        await setWalletModal({ ...walletModal, networkId: event });
      }
    });

    provider.on("chainChanged", async (networkId: number) => {
      await setWalletModal({ ...walletModal, networkId });
    });
  };

  useMount(() => {
    if (web3Modal.cachedProvider) {
      if (web3Modal.cachedProvider === "wanmask" && !window.wanchain) {
        web3Modal.clearCachedProvider();
        return;
      }
      if (web3Modal.cachedProvider === "clover" && !window.clover) {
        web3Modal.clearCachedProvider();
        return;
      }
      connect();
    }
  });

  const getLogo = () => web3Modal.getLogo();

  return (
    <WalletContext.Provider
      children={children}
      value={{ ...walletModal, resetApp, connect, getLogo }}
    />
  );
};

export const useWallet = () => {
  const context = React.useContext(WalletContext);
  if (!context) {
    throw new Error("useWallet必须在WalletProvider中使用");
  }
  return context;
};
