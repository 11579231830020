import styled from "@emotion/styled";
import { Button, Modal } from "antd";
import { useWallet } from "context/wallet-context";
import { useMemo, useState } from "react";
import { StoremanGroupInfo } from "types/pos-staker";
import { cutString, hexCharCodeToStr } from "utils";
import { useLocalStorage } from "context/localstorage-context";

export default function DelegateConfirm({
  visible,
  onCancel,
  storemanInfo,
  amount,
  action,
  type,
}: {
  amount: string;
  visible: boolean;
  onCancel: () => void;
  storemanInfo: StoremanGroupInfo;
  action: (arg: string) => void;
  type?: string;
}) {
  const { address } = useWallet();
  const [loadingBTn, setLoadingBtn] = useState(false);
  const { theme } = useLocalStorage();
  const isdark = useMemo(() => theme === "dark", [theme]);

  const onOk = () => {
    action(amount);
    setLoadingBtn(true);
  };

  return (
    <Modal
      className={isdark ? "darkModal" : "lightModal"}
      maskClosable={true}
      closable={true}
      destroyOnClose={true}
      width={700}
      mask={true}
      open={visible}
      title={
        <Title className="text-light-0 dark:text-white">
          {type ? type : "Confirm Transaction"}
        </Title>
      }
      footer={null}
      onCancel={onCancel}
      style={{ background: "#051F31", borderRadius: "16px" }}
    >
      <Part isdark={isdark} className="bg-white dark:bg-common-1">
        <PartTitle className="text-light-0 dark:text-white">
          Bridge Node Account
        </PartTitle>
        <LineStyle isdark={isdark} />
        <DetailInfo>
          <RowStyle>
            <span>Storeman</span>
            <span>
              <ImgStyle width={25} src={storemanInfo.imgdata} alt="icon" />
              {storemanInfo.name.startsWith("0x")
                ? cutString(storemanInfo.name, 8)
                : storemanInfo.name}
            </span>
          </RowStyle>
          <RowStyle>
            <span>Group</span>
            <span>
              <span>{hexCharCodeToStr(storemanInfo.groupId)} </span>
            </span>
          </RowStyle>
        </DetailInfo>
      </Part>
      <Part isdark={isdark} className="bg-white dark:bg-common-1">
        <PartTitle className="text-light-0 dark:text-white">
          My Account
        </PartTitle>
        <LineStyle isdark={isdark} />
        <DetailInfo>
          <RowStyle>
            <span>Address</span>
            <AddrText>{address}</AddrText>
          </RowStyle>
          <RowStyle>
            <span>Amount</span>
            <span>
              <span style={{ color: "#2FBDF4" }}>{amount || "0"} </span>
              WAN
            </span>
          </RowStyle>
        </DetailInfo>
      </Part>
      {type === "Undelegate WAN" && (
        <Info isdark={isdark}>
          <div>Notice</div>
          <ul style={{ listStyleType: "disc" }}>
            <li>
              Once you undelegate your WAN, the funds will be returned to your
              original wallet address within a period of{" "}
              <span style={{ color: "#fe4949" }}>10 to 30 days</span>.
            </li>
            <li>
              If you perform an undelegate operation within the current month,
              you will be able to manually claim the funds to your account on
              the{" "}
              <span style={{ color: "#fe4949" }}>
                10th of the following month
              </span>
              .
            </li>
          </ul>
        </Info>
      )}
      <BtnArea isdark={isdark}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onOk} loading={loadingBTn}>
          Confirm
        </Button>
      </BtnArea>
    </Modal>
  );
}

const Title = styled.span`
  font-size: 24px;
  font-family: Inter-Bold, Inter;
  font-weight: bold;
`;

const BtnArea = styled.div<{
  isdark: boolean;
}>`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 20px;

  .ant-btn:first-of-type {
    background: ${(props) =>
      props.isdark ? "rgba(255, 255, 255, 0.1)" : "#E5E5E5"};
    border-radius: 16px;
    color: ${(props) => (props.isdark ? "#818d96" : "#999")};
  }
  .ant-btn:first-of-type:hover {
    background: ${(props) =>
      props.isdark ? "rgba(255, 255, 255, 0.1)" : "#E5E5E5"};
  }
  .ant-btn {
    width: calc((100% - 20px) / 2);
    border-radius: 16px;
    height: 60px;
    color: white;
    border: none;
    background: #0f68aa;
  }
  .ant-btn:hover {
    background: #0f68aa;
  }
`;

const Part = styled.div<{
  isdark: boolean;
}>`
  border-radius: 16px;
  opacity: 1;
  border: ${(props) =>
    props.isdark ? "1px solid rgba(255, 255, 255, 0.1)" : "1px solid #EBEFF2"};
  margin-bottom: 2rem;
  padding-top: 2rem;

  span {
    color: #818d96;
  }
`;

const PartTitle = styled.div`
  font-size: 16px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  line-height: 19px;
  background-clip: text;
  margin-left: 2rem;
  text-align: left;
`;

const LineStyle = styled.div<{
  isdark: boolean;
}>`
  width: 100%;
  margin: 2rem 0;
  opacity: 0.2;
  border: ${(props) =>
    props.isdark ? "1px solid rgba(255, 255, 255, 0.1)" : "1px solid #EBEFF2"};
`;

const DetailInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 2rem 2rem;
  gap: 1rem;
`;

const RowStyle = styled.div`
  color: #757575;
  display: flex;
  justify-content: space-between;

  p {
    font-size: 16px;
  }
`;

const ImgStyle = styled.img`
  border-radius: 50%;
  border: 1px solid #757575;
  margin-right: 3px;
  display: inline-block;
`;

const AddrText = styled.span`
  @media (max-width: 768px) {
    width: 70%;
    white-space: break-spaces;
    text-align: right;
  }
`;

const Info = styled.div<{
  isdark: boolean;
}>`
  font-size: 14px;
  font-family: Inter-Regular, Inter;
  font-weight: 400;
  color: ${(props) => (props.isdark ? "#818d96" : "#999")};
  margin-top: 20px;
  text-align: left;
  border: ${(props) =>
    props.isdark ? "1px solid rgba(255, 255, 255, 0.1)" : "1px solid #F5F5F5"};
  border-radius: 16px;
  padding: 15px;
  background-color: ${(props) => (props.isdark ? "#051F31" : "#F5F5F5")};

  ul {
    padding-left: 1rem;
  }
`;
